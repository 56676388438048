import ReactGA from 'react-ga';

const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID;

const configureGoogleAnalytics = () => {
  ReactGA.initialize(gaTrackingId, {
    gaOptions: {
      storage: false,
    },
  });
  ReactGA.set({ anonymizeIp: true });
  ReactGA.set({ allowAdFeatures: false });
  ReactGA.set({ require: 'ec' });
  ReactGA.ga((tracker) => {
    ReactGA.set({ dimension6: tracker.get('clientId') });
  });
};

export const registerGaPageView = (path) => {
  if (path && ReactGA.ga()) {
    ReactGA.pageview(path);
  }
};

export const sendEvent = ({ category, action, label }) => {
  ReactGA.event({
    category, action, label,
  });
};

export default configureGoogleAnalytics;
