const getCleanedComment = (comment) => {
  // * u0020-u0021: [space] !
  // * u003F      : ?
  // * u0028-u0029: ( )
  // * u002C-u002E: , - .
  // * u0030-u003A: [0-9] :
  // * u0041-u005A: [A-Z]
  // * u0061-u007A: [a-z]
  // * u00C0-u00D6: À Á Â Ã Ä Å Æ Ç È É Ê Ë Ì Í Î Ï Ð Ñ Ò Ó Ô Õ Ö
  // * u00D8-u00F6: Ø Ù Ú Û Ü Ý Þ ß à á â ã ä å æ ç è é ê ë ì í î ï ð ñ ò ó ô õ ö
  // * u00F8-u00FF: ø ù ú û ü ý þ ÿ

  const commentCleanerRegex = /[^\u0020-\u0021\u003F\u0028-\u0029\u002C-\u002E\u0030-\u003A\u0041-\u005A\u0061-\u007A\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]/g;
  return comment.replace(commentCleanerRegex, '');
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getCleanedComment,
};
