const POLL_ALTERNATIVES = [
  {
    question: 'Ferie 🌴',
    answer: 'Godt valg og god tur! ☀️',
    id: 1,
  },
  {
    question: 'Putte alt på sparekonto 🏦',
    answer: 'Fornuftig valg og god sparing! 💰',
    id: 2,
  },
  {
    question: 'Shopping - jeg trenger ny garderobe! 🧥🛍️',
    answer: 'Gøy valg og god shopping! 👜',
    id: 3,
  },
  {
    question: 'Investere i fond eller aksjer 📈',
    answer: 'Fornuftig valg og god investering 💹',
    id: 4,
  },
  {
    question: 'Strømregningen⚡',
    answer: 'Fornuftig valg i disse dager! 💡',
    id: 5,
  },
  {
    question: 'Dagligvarer  – alt er blitt så dyrt! 🍞🥛',
    answer: 'Fornuftig valg og god handling! 🛒',
    id: 6,
  },
  {
    question: 'Jeg gir pengene til noen som trenger det❤️',
    answer: 'For et fint valg – det vil glede de som mottar! 🥰',
    id: 7,
  },
  {
    question: 'Oppussing 🔨',
    answer: 'Spennende valg og lykke til! 🏠',
    id: 8,
  },
  {
    question: 'Jeg fortjener en dag på spa 💆',
    answer: 'Godt valg og nyt spadagen 💦',
    id: 9,
  },
  {
    question: 'Annet: Vennligst utdyp ✏️',
    answer: 'Godt valg! 😊',
    id: 10,
  },
];

const getPollAlternative = (id) => POLL_ALTERNATIVES.find((a) => a.id === id);

export {
  POLL_ALTERNATIVES,
  getPollAlternative,
};
