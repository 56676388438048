import { GlobalStyle, spvNewTheme, ThemeProvider } from '@sparebankenvest/splash-ui';
import React from 'react';
import ReactDOM from 'react-dom';
import configureGoogleAnalytics from './infrastructure/googleAnalytics';
import RouterContainer from './app/RouterContainer';

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={spvNewTheme}>
        <GlobalStyle />
        <RouterContainer />
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

const runApp = async () => {
  configureGoogleAnalytics();
  renderApp(spvNewTheme);
};

runApp();
