import { colors } from '@sparebankenvest/splash-ui';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

const HeaderWrapper = styled.header`
  flex: 1 100%;
  background: white;
  height: ${rem(80)};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.02),
        0px 1px 4px rgba(13, 13, 18, 0.04), 0px 5px 0px ${colors.yellow['200']};
`;

const Logo = styled.img`
  width: ${rem(220)};
  margin-top: ${rem(20)};
  margin-left: ${rem(20)};
`;

const LogoWrapper = styled.div`
  max-width: ${rem(1170)};
  margin: 0 auto;
`;

function Header() {
  return (
    <HeaderWrapper>
      <LogoWrapper>
        <Logo
          src="https://cdn.spv.no/static/assets/images/1.0.22/spv-logo.svg"
          alt="Logo for Sparebanken Vest"
        />
      </LogoWrapper>
    </HeaderWrapper>
  );
}

export default Header;
