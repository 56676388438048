import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Layout = styled.div`
  display: flex;
  flex-flow: row wrap;
  min-height: 100vh;
`;

const Content = styled.article`
  max-width: ${rem(1170)};
  margin: 0 auto;
  padding: ${rem(20)};
`;

function AppContainer({ children }) {
  return (
    <Layout>
      <Header />
      <Content>
        {children}
      </Content>
      <Footer />
    </Layout>
  );
}

AppContainer.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppContainer;
