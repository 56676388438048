import React from 'react';

export default function Feilplakat() {
  return (
    <>
      <h2>
        Det skjedde en feil
      </h2>
      <a href="/">
        Gå tilbake
      </a>
    </>
  );
}
