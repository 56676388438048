import React from 'react';
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';
import AppContainer from './AppContainer';
import KundeutbyttePoll from '../screens/KundeutbyttePoll/KundeutbyttePoll';

function RouterContainer() {
  return (
    <BrowserRouter>
      <AppContainer>
        <Routes>
          <Route exact path="/" element={<KundeutbyttePoll />} />
        </Routes>
      </AppContainer>
    </BrowserRouter>
  );
}

export default RouterContainer;
