import 'focus-visible/dist/focus-visible';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SpvMobileDetect from '@sparebankenvest/device-detect';
import Feilplakat from '../../components/Feilplakat';
import Kvittering from '../../components/Kvittering';
import Poll from '../../components/Poll';
import { registerGaPageView, sendEvent } from '../../infrastructure/googleAnalytics';
import { getCleanedComment } from '../../utils/comment.utils';
import PengeFolkIllustrasjon from './images/pengefolk.png';
import { getPollAlternative } from './kundeutbyttePoll.helper';

const KundeutbytteLogo = styled.img`
  width: ${rem(300)};
`;

const CenterWrapper = styled.div`
  text-align: center;
`;

const KundeutbyttePollWrapper = styled.div`
  font-size: ${rem(18)};
`;

function KundeutbyttePoll() {
  useEffect(() => {
    registerGaPageView('kundeutbytte-poll');
  }, []);

  const mobileDetect = new SpvMobileDetect();
  const isApp = mobileDetect.isApp();

  const [activePage, setActivePage] = useState('poll');
  const [selectedValue, setSelectedValue] = useState(getPollAlternative(1));
  const [comment, setComment] = useState('');

  const registerAnswerAndRedirect = async () => {
    let cleanedComment = getCleanedComment(comment);

    if (cleanedComment.length > 255) {
      cleanedComment = cleanedComment.substring(0, 255);
    }

    setComment(cleanedComment);

    if (selectedValue.id === 10 && (!cleanedComment || cleanedComment.length === 0)) {
      setActivePage('kvittering');
      return;
    }

    const data = {
      questionId: 1,
      choiceId: selectedValue.id,
      choiceText: cleanedComment,
    };

    await fetch('/api/submitvote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        setActivePage('kvittering');
        sendEvent({
          category: 'Kundeutbytte Poll',
          action: 'Send svar',
          label: `Svaralternativ ${selectedValue.id}`,
        });
      } else {
        setActivePage('feilplakat');
      }
    }).catch(() => {
      setActivePage('feilplakat');
    });
  };

  const onCommentChange = (event) => {
    const newComment = event.target.value;
    setComment(newComment);
  };

  const shouldShowFreeText = selectedValue.id === 10;

  return (
    <KundeutbyttePollWrapper>
      <CenterWrapper>
        <KundeutbytteLogo
          aria-label="Illustrasjon av mennesker og penger"
          src={PengeFolkIllustrasjon}
        />
      </CenterWrapper>
      {activePage === 'kvittering' && (<Kvittering pollAnswer={selectedValue.answer} isApp={isApp} />)}
      {activePage === 'feilplakat' && (<Feilplakat />)}
      {activePage === 'poll' && (
        <Poll
          comment={comment}
          onCommentChange={onCommentChange}
          registerAnswerAndRedirect={registerAnswerAndRedirect}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          shouldShowFreeText={shouldShowFreeText}
        />
      )}
    </KundeutbyttePollWrapper>
  );
}

export default KundeutbyttePoll;
