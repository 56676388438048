import {
  PrimaryButton,
  Card,
  Radio,
  Textarea,
} from '@sparebankenvest/splash-ui';
import React from 'react';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { POLL_ALTERNATIVES } from '../screens/KundeutbyttePoll/kundeutbyttePoll.helper';

const PollCard = styled(Card)`
  margin-top: ${rem(30)};
  margin-bottom: ${rem(30)};
  padding: ${rem(20)};
`;

const Title = styled.h1`
  margin-top: ${rem(10)};
  @media (max-width: ${rem(768)}) {
    font-size: ${rem(24)};
  }
`;

const Textbox = styled(Textarea)`
  font-size: ${rem(16)};
  margin: 0;
`;

export default function Poll({
  shouldShowFreeText,
  selectedValue,
  setSelectedValue,
  comment,
  onCommentChange,
  registerAnswerAndRedirect,
}) {
  return (
    <>
      <Title>
        Hva vil du bruke kundeutbyttet ditt på?
      </Title>
      <PollCard>
        {POLL_ALTERNATIVES.map((a) => (
          <Radio
            val={selectedValue.id.toString()}
            id={a.id.toString()}
            key={a.id}
            label={a.question}
            input={{
              value: a.id.toString(),
              name: a.question,
              onChange: () => setSelectedValue(a),
            }}
          />
        ))}
        {shouldShowFreeText && (
          <Textbox
            input={{
              onChange: onCommentChange,
              placeholder: 'Skriv din kommentar her',
              value: comment,
              required: true,
            }}
            meta={{ active: true }}
            onChange={onCommentChange}
            label=""
          />
        )}
      </PollCard>
      <PrimaryButton onClick={registerAnswerAndRedirect}>
        Send inn
      </PrimaryButton>
    </>
  );
}

Poll.propTypes = {
  shouldShowFreeText: PropTypes.bool.isRequired,
  selectedValue: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  setSelectedValue: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired,
  onCommentChange: PropTypes.func.isRequired,
  registerAnswerAndRedirect: PropTypes.func.isRequired,
};
