import { colors } from '@sparebankenvest/splash-ui';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  flex: 1 100%;
  background: ${colors.blue['900']};
  min-height: ${rem(120)};
`;

const Logo = styled.img`
  width: ${rem(200)};
  margin-top: ${rem(30)};
  margin-left: ${rem(20)};
`;

const LogoWrapper = styled.div`
  max-width: ${rem(1170)};
  margin: 0 auto;
`;

function Footer() {
  return (
    <FooterWrapper>
      <LogoWrapper>
        <Logo
          src="https://cdn.spv.no/static/assets/images/1.0.22/spv-logo-negativ.svg"
          alt="Logo for Sparebanken Vest"
        />
      </LogoWrapper>
    </FooterWrapper>
  );
}

export default Footer;
