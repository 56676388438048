import { Card } from '@sparebankenvest/splash-ui';
import React from 'react';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { sendEvent } from '../infrastructure/googleAnalytics';

const KvitteringCard = styled(Card)`
  margin-top: ${rem(30)};
  margin-bottom: ${rem(30)};
  padding: ${rem(20)};
  word-break: break-word;
  word-wrap: break-word;
`;

const KvitteringTekst = styled.p`
  font-size: ${rem(24)};
  font-weight: 600;
  padding: 0;
  margin: 0;

  @media (max-width: ${rem(768)}) {
    font-size: ${rem(18)};
  }
`;

const Disclaimer = styled.p`
  margin-top: ${rem(20)};
  font-size: ${rem(14)};
`;

const onLinkClick = (event) => {
  event.preventDefault();

  const { currentTarget } = event;
  if (currentTarget) {
    const hrefValue = currentTarget.getAttribute('href');

    sendEvent({
      category: 'Kundeutbytte Poll',
      action: 'Lenkeklikk',
      label: 'spv.no/kundeutbytte',
    });

    window.location.href = hrefValue;
  }
};

export default function Kvittering({ pollAnswer, isApp }) {
  return (
    <>
      <KvitteringCard>
        <KvitteringTekst>
          {pollAnswer}
        </KvitteringTekst>
      </KvitteringCard>
      {!isApp && (
        <>
          {'Les mer om kundeutbytte og lek deg med utbyttekalkulatoren vår på '}
          <a
            href="https://www.spv.no/kundeutbytte"
            onClick={(e) => onLinkClick(e)}
          >
            spv.no/kundeutbytte
          </a>
        </>
      )}
      <Disclaimer>
        Kundeutbytte er en del av overskuddet til Sparebanken Vest
        og blir endelig vedtatt på generalforsamlingen i mars.
      </Disclaimer>
    </>
  );
}

Kvittering.propTypes = {
  pollAnswer: PropTypes.string.isRequired,
  isApp: PropTypes.bool.isRequired,
};
